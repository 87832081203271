import React from "react"
import Layout from '../components/layout';
import {Link} from 'gatsby'
import SEO from '../components/seo'


// import Layout from "../components/layout"


const NotFoundPage = () => (
  // <Layout>
  //   <SEO title="404: Not found" />
  //   <h1>NOT FOUND</h1>
  //   <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  // </Layout>

  <Layout location="">
    <div className="page-not-found-root">
      <SEO title="404 - Not Found" description="404 page not found"/>
      <div className="page-not-found">
        404 - Page Not Found
        <div className="back-to-home-wrapper"><Link to='/'>Home Page</Link></div>
      </div>
    </div>
  </Layout>
  

)

export default NotFoundPage
